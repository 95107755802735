import type { IconProps } from "./SvgIcon";
import SvgIcon from "./SvgIcon";

export default function WarningIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      paths={
        <path fill="currentColor" d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"></path>
      }
    />
  );
}
